<template>
  <div class="NumStatistics" :class="isTabs?'border':'bgColor'">
    <!-- 状态标签 -->
    <div class="status" :info="statisticsInfo">
      <slot name="status" />
    </div>
    <!-- 标题 -->
    <div v-if="statisticsInfo.titleLabel" class="title-cell flex-center">
      <span class="title">{{ statisticsInfo.titleLabel }}</span>
      <div class="border-right" />
    </div>
    <!-- 业务区 -->
    <ul class="content-list" :style="contentFlex">
      <li v-for="(tab,index) in statisticsInfo.tabList" :key="index" :class="tab.selected?'selected':''">
        <div :class="tab.borderStyle" />
        <div class="tab-content" @click="changeTab(tab,index)">
          <span class="tab-num">{{ tab.value||'--' }}</span>
          <span class="tab-label">{{ tab.label }}<span v-if="tab.unit">({{ tab.unit }})</span></span>
        </div>
      </li>
    </ul>
    <!-- 按钮操作区 -->
    <div v-if="isHandle" class="handle-cell flex-center">
      <slot name="handle" :info="statisticsInfo" />
      <div class="border-left" />
    </div>
    <!-- 当列少于4时 按5列处理其余的为空元素 -->
    <!-- 补全列 -->
    <div :style="completionFlex" />
  </div>
</template>

<script>
export default {
  name: 'NumStatistics',
  props: {
    // 接收数据
    statisticsInfo: {
      type: Object,
      default: () => {}
    },
    // 是否是可点击的tabs项
    isTabs: {
      type: Boolean,
      default: false
    },
    // 控制操作区是否显影
    isHandle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    contentFlex() {
      const flex = this.statisticsInfo.tabList?.length
      return `flex:${flex}`
    },
    completionFlex() {
      let elementLength = this.statisticsInfo.tabList?.length
      if (this.statisticsInfo.titleLabel) {
        elementLength++
      }
      if (this.isHandle) {
        elementLength++
      }
      if (elementLength < 4) {
        return `flex:${5 - elementLength}`
      }
      return ''
    }
  },
  mounted() {

  },
  methods: {
    changeTab(tab, index) {
      if (!this.isTabs) return
      this.statisticsInfo?.tabList?.forEach((v) => {
        v.selected = false
      })
      tab.selected = true
      this.$emit('changeTab', tab, index)
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
.NumStatistics {
  height: 88px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
.status {
  position: absolute;
  left: 0;
  top: 0;
}
.title-cell {
  flex: 1;
  position: relative;
  .title {
    color: @sc-grey-100;
    font-weight: 700;
    .font-size(16px);
  }
}
.handle-cell {
  flex: 1;
  position: relative;
  padding: 0;
}
.content-list {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .tab-content {
      display: flex;
      flex-direction: column;
    }
    span {
      width: 100%;
      text-align: left;
    }
    .tab-num {
      .font-size(20px);
      font-weight: 700;
      color: @sc-grey-100;
    }
    .tab-label {
      .font-size(16px);
      color: @sc-grey-60;
    }
  }
}
.border {
  border: 1px solid @sc-greyBg-20;
  .selected .tab-content > span,.tab-content:hover > span {
    cursor: pointer;
    color: @sc-primary-100;
  }
}
.bgColor {
  background: @sc-greyBg-10;
}
.border-left {
  position: absolute;
  left: 0;
  height: 30px;
  border-left: 1px solid @sc-greyBg-20;
}
.border-right {
  position: absolute;
  right: 0;
  height: 30px;
  border-left: 1px solid @sc-greyBg-20;
}
</style>
