var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "NumStatistics", class: _vm.isTabs ? "border" : "bgColor" },
    [
      _c(
        "div",
        { staticClass: "status", attrs: { info: _vm.statisticsInfo } },
        [_vm._t("status")],
        2
      ),
      _vm.statisticsInfo.titleLabel
        ? _c("div", { staticClass: "title-cell flex-center" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.statisticsInfo.titleLabel)),
            ]),
            _c("div", { staticClass: "border-right" }),
          ])
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "content-list", style: _vm.contentFlex },
        _vm._l(_vm.statisticsInfo.tabList, function (tab, index) {
          return _c(
            "li",
            { key: index, class: tab.selected ? "selected" : "" },
            [
              _c("div", { class: tab.borderStyle }),
              _c(
                "div",
                {
                  staticClass: "tab-content",
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(tab, index)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "tab-num" }, [
                    _vm._v(_vm._s(tab.value || "--")),
                  ]),
                  _c("span", { staticClass: "tab-label" }, [
                    _vm._v(_vm._s(tab.label)),
                    tab.unit
                      ? _c("span", [_vm._v("(" + _vm._s(tab.unit) + ")")])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm.isHandle
        ? _c(
            "div",
            { staticClass: "handle-cell flex-center" },
            [
              _vm._t("handle", null, { info: _vm.statisticsInfo }),
              _c("div", { staticClass: "border-left" }),
            ],
            2
          )
        : _vm._e(),
      _c("div", { style: _vm.completionFlex }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }